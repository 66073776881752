import React from 'react'

export default () => (
    <svg
        x="0px"
        y="0px"
        viewBox="0 0 580.865 580.865"
        className="w-16 text-gray-200 dark:text-slate-600 sm:w-32 lg:w-40"
        fill="currentColor"
    >
        <g>
            <g>
                <path
                    d="M577.949,451.845c-0.123-1.529-0.723-2.781-1.82-3.855c-3.525-3.439-7.059-6.869-10.514-10.375
			c-1.236-1.256-2.678-2.035-4.305-2.549c-1.025-0.322-2.068-0.596-3.105-0.883c-11.705-3.211-23.24-7.066-35.182-9.387
			c-0.795-0.156-1.562-0.637-2.256-1.098c-4.424-2.934-8.83-5.896-13.223-8.879c-0.625-0.424-1.307-0.893-1.703-1.51
			c-1.574-2.455-3.178-4.908-4.527-7.486c-2.854-5.451-5.146-11.232-8.396-16.418c-6.141-9.801-11.73-19.854-16.316-30.465
			c-0.489-1.131-1.044-2.229-1.546-3.354c-0.661-1.473-1.437-2.91-1.93-4.436c-2.229-6.875-4.382-13.773-6.545-20.67
			c-1.403-4.471-2.562-9.029-4.198-13.41c-3.773-10.098-8.841-19.625-13.387-29.38c-0.387-0.833-0.894-1.677-1.003-2.562
			c-0.429-3.431-1.004-6.883-0.996-10.322c0.013-6.614,0.314-13.231,0.633-19.841c0.461-9.641-0.147-19.225-1.354-28.78
			c-0.604-4.762-2.167-9.258-3.868-13.725c-0.53-1.399-0.763-2.97-0.836-4.476c-0.172-3.606-0.131-7.226-0.233-10.84
			c-0.216-7.748-1.48-15.296-4.442-22.501c-0.376-0.914-0.833-1.812-1.367-2.644c-1.448-2.269-2.889-4.565-5.585-5.353
			c-0.204-1.583,0.669-2.419,1.525-3.15c2.081-1.779,2.795-1.758,5.223-0.208c0.616,0.396,1.195,1.073,2.166,0.412
			c0.363-2.207,0.743-4.504,1.126-6.801c0.139-0.037,0.277-0.078,0.416-0.114c0.4,0.722,0.8,1.444,1.204,2.163
			c1.118-1.061,1.583-1.738,1.982-2.457c1.465-2.611,3.012-5.145,4.819-7.56c4.206-5.626,6.548-12.012,6.34-19.115
			c-0.065-2.179,0.604-3.586,2.42-4.745c2.207-1.408,3.61-3.488,4.349-6.039c0.82-2.823,1.384-5.663,1.285-8.629
			c-0.094-2.771-0.098-5.541,0.734-8.238c1.008-3.268,2.012-6.54,2.999-9.812c0.326-0.665,0.539-1.363,0.62-2.069
			c0.29-0.963,0.575-1.93,0.861-2.893c0.388-1.318,0.652-2.693,0.783-4.06c0.265-2.729-2.317-6.622-4.888-7.43
			c-3.652-1.146-7.238-1.073-10.727,0.689c-1.628,0.824-3.305,1.554-5.108,2.395c-0.93-1.591-1.771-3.027-2.623-4.488
			c0.771-0.697,1.42-1.289,2.329-2.113c-0.889-0.628-1.538-1.105-2.211-1.555c-2.489-1.665-5.153-3.117-7.434-5.026
			c-3.239-2.713-6.223-5.732-9.266-8.67c-2.501-2.416-5.655-3.619-8.78-4.896c-0.106-0.045-0.281,0.078-0.429,0.123
			c-0.216,0.432-0.433,0.861-0.701,1.387c-3.081-1.958-6.271-2.134-9.666-1.701c-3.586,0.457-7.205,0.445-10.771-0.457
			c-1.771-0.449-3.582-0.734-5.401-1.102c-0.208,0.408-0.392,0.661-0.465,0.942c-0.09,0.343-0.095,0.71-0.155,1.253
			c-2.587-1.216-5.026-2.285-7.903-2.305c-0.017,2.554-1.701,3.146-3.623,3.497c-1.057,0.191-2.109,0.424-3.158,0.673
			c-0.746,0.176-1.533,0.294-2.207,0.628c-4.292,2.134-8.241,4.786-11.804,8.001c-0.705,0.636-1.334,1.367-2.011,2.068
			c-0.723-0.881-1.281-1.566-2.077-2.542c-1.701,2.411-3.235,4.578-4.794,6.785c-0.596-0.53-1.085-0.963-1.685-1.497
			c-5.031,3.554-6.504,9.184-8.834,14.639c-2.04-1.416-3.994-2.611-5.757-4.043c-1.677-1.367-3.606-2.048-5.609-2.566
			c-2.403-0.62-4.17,0.833-4.202,3.207c-0.009,0.702,0.064,1.44,0.228,2.154c0.033,0.249,0.099,0.494,0.2,0.726
			c0.103,0.31,0.225,0.608,0.371,0.89c1.571,3.072,3.456,5.981,5.047,9.041c0.131,0.253,0.262,0.506,0.393,0.763
			c0.008,0.024,0.016,0.044,0.024,0.069c1.424,4.08,2.011,7.67,2.044,11.832c0.004,0.775,0.277,1.497,0.718,2.081
			c0.032,0.669,0.058,1.334,0.073,2.003c0.09,3.97,0.343,7.825,2.399,11.354c0.53,0.91,0.277,1.599-0.727,2.02
			c-1.064,0.445-2.105,0.938-3.162,1.399c-6.695,2.913-13.37,5.871-20.094,8.719c-4.251,1.799-8.458,3.619-11.836,6.907
			c-0.714,0.698-1.518,1.302-2.293,1.934c-2.351,1.921-5.015,3.113-7.919,4.08c-10.658,3.549-21.608,6.038-32.368,9.196
			c-8.992,2.64-18.083,4.949-26.508,9.237c-3.146,1.604-6.446,2.909-9.669,4.362c-0.698,0.314-1.42,0.612-2.056,1.032
			c-6.161,4.068-12.521,7.833-18.213,12.591c-2.591,2.167-5.177,4.431-8.066,6.145c-10.2,6.055-19.972,12.746-29.662,19.56
			c-3.146,2.211-6.186,4.574-9.315,6.805c-0.918,0.653-1.914,1.33-2.978,1.624c-7.165,1.999-13.827,5.083-20.172,8.935
			c-5.455,3.313-10.983,6.512-16.507,9.707c-0.975,0.563-2.077,1.016-3.174,1.252c-13.158,2.815-26.275,5.798-39.013,10.18
			c-6.528,2.248-13.093,4.574-19.274,7.609c-7.581,3.725-14.688,8.319-21.212,13.819c-4.133,3.484-8.009,7.111-11.534,11.228
			c-4.088,4.773-7.307,9.984-10.257,15.513c-4.468,8.375-7.189,17.363-9.499,26.41c-2.081,8.146-3.917,16.457-4.186,24.973
			C0.526,348.99-0.004,352.595,0,356.203c0.004,3.84,0.412,7.678,0.657,11.514c0.114,1.768,0.089,3.559,0.416,5.287
			c1.326,7.107,2.783,14.184,5.741,20.846c1.028,2.312,1.726,4.781,2.839,7.051c3.603,7.344,7.875,14.268,13.289,20.439
			c0.812,0.926,1.489,1.967,2.297,2.889c1.521,1.73,3.011,3.502,4.663,5.096c4.537,4.371,9.151,8.658,13.803,13.045
			c-0.114,0.162-0.237,0.461-0.457,0.648c-2.615,2.264-5.141,4.65-7.899,6.729c-4.61,3.467-9.359,6.748-14.068,10.086
			c-3.382,2.398-5.998,5.299-6.401,9.648c-0.273,2.975-0.143,5.961,2.048,8.238c2.782,2.893,5.998,5.299,9.596,7.115
			c10.298,5.186,20.653,10.264,30.922,15.516c3.774,1.93,7.156,4.398,9.743,7.924c3.46,4.711,7.075,9.318,10.788,13.834
			c1.29,1.572,3.019,2.799,4.635,4.08c1.697,1.348,3.697,0.797,5.565,0.531c1.15-0.164,1.942-1.604,1.754-2.848
			c-0.135-0.906-0.404-1.799-0.665-2.682c-0.739-2.508-1.477-5.014-2.269-7.506c-0.327-1.025,0.049-1.408,1.155-1.514
			c0.342,0.682,0.702,1.346,1.02,2.027c2.003,4.324,3.986,8.662,6.014,12.979c0.318,0.674,0.706,1.457,1.293,1.836
			c1.824,1.184,3.705,1.979,6.046,1.105c2.632-0.979,3.444-1.775,3.615-4.578c0.017-0.26,0.11-0.514,0.143-0.66
			c1.383,0.477,2.562,1.053,3.807,1.281c3.594,0.656,6.585-1.596,5.381-5.301c-0.494-1.521-0.641-3.162-0.881-4.758
			c-0.722-4.781-2.036-9.465-2.064-14.365c-0.028-4.924-0.245-9.844-0.449-14.766c-0.139-3.377-1.334-6.135-4.794-7.408
			c-1.008-0.371-1.975-0.857-2.986-1.199c-3.06-1.033-6.087-2.199-9.209-2.988c-6.442-1.623-12.236-4.463-17.409-8.607
			c-0.628-0.502-1.293-0.951-1.962-1.438c2.48-2.586,2.48-2.582,5.414-1.402c0.428,0.172,0.857,0.367,1.302,0.469
			c6.585,1.525,13.146,3.178,19.771,4.512c5.655,1.139,11.387,1.914,17.189,1.58c3.909-0.225,7.826-0.473,11.714-0.918
			c5.875-0.674,11.718-1.477,17.401-3.326c4.415-1.436,8.548-3.443,12.762-5.299c7.736-3.412,14.668-8.082,21.118-13.492
			c4.186-3.514,8.079-7.32,11.73-11.412c7.071-7.936,13.815-16.137,20.335-24.518c3.138-4.035,6.699-7.617,10.453-11.045
			c1.232-1.125,2.664-1.9,4.235-2.533c-0.27,0.955-0.669,1.764-1.098,2.555c-2.574,4.729-5.5,9.303-7.646,14.219
			c-2.827,6.471-5.161,13.17-7.482,19.85c-1.991,5.725-2.089,11.611-0.551,17.482c0.196,0.746,0.759,1.465,1.318,2.027
			c1.102,1.109,2.521,1.641,4.027,2.094c2.709,0.807,5.349,0.359,7.977-0.184c6.397-1.326,12.758-2.812,19.164-4.098
			c2.783-0.559,5.646-1.17,8.445-1.053c4.366,0.184,8.728,0.82,13.048,1.527c6.536,1.068,13.085,1.922,19.711,2.141
			c4.786,0.16,9.083-1.436,13.118-3.762c2.338-1.346,2.574-4.646,0.469-5.875c-1.493-0.873-3.211-1.488-4.908-1.855
			c-2.925-0.637-5.949-0.816-8.858-1.51c-2.75-0.652-5.435-1.645-8.091-2.637c-1.326-0.492-1.387-2.109-0.188-2.867
			c0.641-0.404,1.375-0.768,2.109-0.889c2.35-0.393,4.708-0.801,7.083-0.955c5.986-0.389,11.983-0.617,17.968-0.963
			c2.444-0.139,4.427,0.6,6.259,2.279c4.7,4.318,9.511,8.512,14.305,12.727c2.171,1.904,4.668,2.684,7.626,2.594
			c3.187-0.098,5.495-2.807,3.888-5.959c-0.71-1.396-1.126-2.943-1.689-4.469c3.889-0.73,5.839-5.867,3.411-9.045
			c-5.186-6.807-10.62-13.379-16.92-19.189c-1.722-1.586-3.798-2.65-6.145-2.859c-3.088-0.273-6.188,0.07-9.135,0.955
			c-8.409,2.521-17.01,4.186-25.558,6.088c-0.816,0.18-1.673,0.301-2.505,0.277c-2.081-0.07-2.771-1.07-2.081-3.035
			c0.792-2.248,1.689-4.461,2.538-6.684c2.876-7.535,6.83-14.465,12.02-20.633c4.224-5.014,8.07-10.289,11.583-15.814
			c2.616-4.107,5.554-7.969,9.132-11.264c2.231-2.057,3.647-4.521,4.667-7.279c0.355-0.959,0.51-1.992,0.8-3.158
			c0.449,0.33,0.768,0.525,1.036,0.775c3.55,3.301,7.16,6.531,10.188,10.355c2.007,2.529,4.112,4.988,6.258,7.404
			c4.333,4.871,7.728,10.307,10.425,16.238c1.555,3.424,3.492,6.672,5.214,10.021c1.596,3.104,3.896,5.508,6.965,7.152
			c1.069,0.57,1.849,1.361,2.321,2.459c1.225,2.82,2.428,5.646,3.656,8.463c0.322,0.738,0.689,1.457,1.093,2.305
			c0.62-0.494,0.996-0.791,1.42-1.127c0.91,1.836,1.727,3.574,2.615,5.275c2.077,3.955,5.284,6.834,9.246,8.684
			c3.329,1.551,6.85,2.736,10.367,3.811c6.613,2.02,13.276,3.893,19.947,5.717c1.623,0.443,2.651,1.146,3.243,2.793
			c1.999,5.598,4.867,10.785,7.838,15.912c1.31,2.266,3.068,4.023,5.74,4.635c2.856,0.658,4.921-0.818,4.933-3.707
			c0.004-1.363-0.184-2.775-0.551-4.09c-1.338-4.814-2.795-9.596-4.194-14.393c-0.208-0.707-0.354-1.43-0.53-2.143
			c0.18-0.082,0.359-0.16,0.543-0.24c0.429,0.734,0.95,1.432,1.269,2.211c2.534,6.189,4.99,12.416,7.568,18.588
			c0.551,1.314,1.363,2.607,2.33,3.648c2.57,2.754,7.466,2.455,9.73-0.596c0.768-1.037,1.502-1.531,2.799-1.566
			c1.689-0.051,2.946-0.939,3.252-2.711c0.261-1.5,0.519-3.027,0.502-4.541c-0.013-1.203,0.065-2.154,0.922-3.148
			c0.763-0.887,1.424-2.113,1.538-3.248c0.208-2.125,0.217-4.334-0.094-6.447c-0.914-6.234-2.003-12.443-3.076-18.652
			c-0.139-0.812-0.51-1.6-0.869-2.355c-1.232-2.574-3.378-4.059-5.961-5.123c-13.149-5.432-26.063-11.389-38.854-17.619
			c-0.963-0.469-1.91-0.998-2.791-1.607c-3.546-2.447-7.066-4.928-10.579-7.424c-0.682-0.486-1.363-1.025-1.89-1.666
			c-3.337-4.055-6.075-8.502-8.522-13.141c-3.958-7.5-7.026-15.365-9.919-23.326c-5.438-14.957-10.588-30.025-17.038-44.594
			c-0.498-1.123-1.02-2.24-1.607-3.318c-3.562-6.557-7.152-13.096-10.718-19.648c-0.282-0.518-0.47-1.085-0.796-1.844
			c3.391-1.726,6.581-3.582,9.955-5.019c9.421-4.01,18.634-8.417,27.442-13.643c0.489-0.29,1.077-0.424,1.693-0.657
			c0.306,0.747,0.559,1.302,0.759,1.873c2.819,8.135,5.553,16.303,7.568,24.684c0.358,1.489,0.795,2.983,0.926,4.5
			c0.433,4.905,0.804,9.813,1.069,14.725c0.195,3.615,0.057,7.221-1.326,10.66c-0.314,0.783-0.579,1.592-0.796,2.408
			c-0.098,0.371-0.016,0.791-0.016,1.416c2.501-0.932,3.843-3.127,6.426-4.129v8.236c1.505-0.311,2.656-0.543,3.961-0.812
			c0.902,3.799,1.759,7.455,2.636,11.111c0.906,3.789,2.648,7.201,4.807,10.412c0.176,0.26,0.583,0.363,1,0.607
			c0.881-1.783-0.303-3.994,1.55-5.568c0.996,1.736,1.86,3.357,2.828,4.912c4.386,7.045,8.498,14.295,14.716,19.996
			c0.616,0.566,1.098,1.285,1.62,1.949c4.022,5.158,7.948,10.396,12.072,15.473c6.757,8.311,12.864,17.066,18.658,26.062
			c5.667,8.797,11.473,17.502,17.218,26.246c3.011,2.686,2.982,6.242,2.632,9.787c-0.404,4.076-0.273,8.125-0.106,12.205
			c0.106,2.582,0.836,4.609,2.758,6.254c0.698,0.596,1.379,1.24,2.154,1.717c7.324,4.533,14.695,8.984,21.99,13.566
			c2.857,1.795,5.746,3.635,8.25,5.867c2.42,2.158,5.215,2.697,8.168,3.293c3.547,0.713,6.182-3.791,5.504-6.291
			c-0.551-2.033-1.529-4.084-2.818-5.746c-1.584-2.039-3.559-3.838-5.594-5.449c-2.158-1.711-4.557-3.127-6.904-4.582
			c-1.473-0.914-2.709-1.979-3.574-3.828c0.686-0.375,1.281-0.949,1.926-1.008c1.291-0.117,2.652-0.162,3.9,0.127
			c7.639,1.754,15.26,3.586,22.869,5.455c0.947,0.232,1.861,0.727,2.701,1.24c3.871,2.367,7.67,4.859,11.584,7.156
			c2.443,1.432,5.033,2.611,7.576,3.867c1.648,0.812,3.422,0.846,5.193,0.596c3.443-0.48,4.676-3.031,2.934-5.996
			c-0.408-0.689-0.846-1.363-1.412-2.266c1.832,0.58,3.301,1.289,4.836,1.486c3.859,0.492,6.898-1.412,6.438-5.326
			c-0.053-0.455,0.131-1.186,0.461-1.402c1.338-0.891,2.061-2.188,2.713-3.562v-1.848c-0.723-1.404-1.135-2.967-2.398-4.092
			C578.059,453.22,577.994,452.437,577.949,451.845z M82.359,397.097c-5.23,5.312-10.677,10.416-14.953,16.613
			c-2.88,4.178-6.051,8.156-9.094,12.219c-0.449,0.6-0.938,1.172-1.616,2.012c-3.032-2.709-6.141-5.115-8.796-7.951
			c-3.893-4.15-7.458-8.602-11.163-12.93c-0.298-0.352-0.616-0.693-0.869-1.078c-4.675-7.201-8.331-14.898-10.824-23.104
			c-0.885-2.922-1.126-6.039-1.62-9.074c-1.44-8.883-1.041-17.773-0.286-26.691c0.808-9.543,2.958-18.826,5.243-28.078
			c0.657-2.664,1.991-5.154,2.913-7.762c2.252-6.363,6.055-11.908,9.327-17.73c0.257-0.461,0.645-0.865,1.02-1.24
			c3.211-3.211,6.422-6.422,9.669-9.597c0.596-0.579,1.314-1.065,2.044-1.473c4.627-2.599,9.258-5.186,13.913-7.728
			c1-0.547,2.085-0.987,3.182-1.306c4.056-1.191,8.091-2.48,12.211-3.407c3.893-0.873,7.903-1.191,11.808-2.016
			c4.598-0.971,8.976,0.355,13.77,0.661c-0.91,2.415-1.383,4.659-3.109,6.36c-0.93,0.918-1.82,1.881-2.688,2.86
			c-0.273,0.306-0.412,0.73-0.755,1.363c2.338-0.114,3.656-1.946,5.871-2.387c-2.032,4.341-3.619,8.548-4.627,13.473
			c2.53-1.265,3.276-3.607,5.394-4.978c-0.853,3.599-2.187,6.858-1.477,10.425c0.853,0.113,1.587,0.213,2.558,0.342
			c-0.139,2.633-0.273,5.203-0.428,8.137c1.342-0.197,2.403-0.352,3.603-0.527c-0.93,5.387-1.836,10.629-2.742,15.875
			c0.155,0.115,0.306,0.229,0.461,0.344c0.686-0.338,1.367-0.682,2.24-1.113c0.526,2.373,0.045,4.729,0.27,7.033
			c0.216,2.273,0.587,4.529,0.889,6.797c1.787,0.285,1.873-1.619,3.17-1.982c1.501,4.076-0.139,8.164,0.082,12.506
			c1.2-0.238,2.187-0.434,3.488-0.689c-0.983,2.104-1.836,3.936-2.827,6.059c2.187,0.207,3.174-1.67,5.067-1.885
			c0.236,0.877,0.526,1.799,0.726,2.736c0.918,4.285,1.836,8.557,1.726,12.992c-0.077,3.08-1.289,5.703-3.121,7.98
			c-2.615,3.246-5.753,5.91-9.408,8.041c-5.247,3.061-10.286,6.471-15.496,9.6c-4.623,2.779-9.066,5.803-13.317,9.115
			C83.297,396.3,82.787,396.66,82.359,397.097z M249.223,362.054c-2.648,4.773-5.296,9.551-7.899,14.35
			c-0.555,1.027-1.261,1.574-2.423,1.863c-3.276,0.82-6.516,1.791-9.768,2.723c-0.514,0.146-1.024,0.33-1.51,0.555
			c-6.552,3.023-12.301,7.246-17.711,11.961c-0.575,0.502-1.057,1.115-1.546,1.711c-6.548,7.963-13.077,15.939-19.649,23.883
			c-1.171,1.416-2.485,2.723-3.786,4.023c-1.032,1.033-2.179,1.951-3.215,2.979c-6.414,6.365-13.545,11.873-21.008,16.9
			c-3.468,2.332-7.389,4.104-11.285,5.67c-6.157,2.477-12.648,3.84-19.25,4.459c-6.585,0.617-13.187,0.654-19.812,0.262
			c-5.08-0.297-9.771-1.918-14.562-3.293c-0.412-0.117-0.804-0.318-1.269-0.506c0.179-0.33,0.241-0.578,0.396-0.705
			c3.794-3.121,7.376-6.475,11.591-9.107c15.414-9.623,31.404-18.168,47.834-25.902c2.991-1.408,6.014-2.742,9.062-4.02
			c3.558-1.488,6.512-3.803,8.911-6.74c2.167-2.648,3.333-5.871,4.284-9.131c2.326-7.969,4.766-15.912,6.818-23.949
			c1.175-4.604,1.746-9.363,2.472-14.076c1.595-10.352,2.211-20.797,2.823-31.236c0.021-0.359,0.106-0.711,0.208-1.379
			c0.575,0.518,0.975,0.824,1.305,1.188c3.754,4.129,5.953,8.902,6.059,14.557c0.008,0.535,0.253,1.061,0.53,2.154
			c1.11-1.484,1.865-2.492,2.774-3.717c0.625,0.75,1.122,1.391,1.665,1.99c1.241,1.363,2.457,2.75,3.766,4.049
			c1.289,1.271,1.383,1.223,2.807-0.055c0.338-0.305,0.685-0.6,1.105-0.971c0.628,0.33,1.257,0.613,1.828,0.971
			c3.66,2.307,7.658,3.9,11.587,5.66c2.864,1.281,5.781,1.473,8.796,1.191c1.91-0.18,3.807-0.502,5.712-0.752
			c0.273-0.035,0.555-0.004,1.057-0.004c-0.841,1.236-2.424,1.367-3.183,3c1.473,0,2.599,0.154,3.668-0.029
			c2.827-0.486,5.516,0.225,8.201,0.836c1.771,0.404,2.905,1.707,3.297,3.492C250.312,358.691,250.125,360.421,249.223,362.054z"
                />
            </g>
        </g>
    </svg>
)
